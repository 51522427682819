export function useValidation() {
  const validationErrors = ref<string[]>([]);

  function validateSamePassword(event: Event, passwordToCompareAgainst: string) {
    if (passwordToCompareAgainst === '') {
      return;
    }

    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    const password = event.target.value;
    if (passwordToCompareAgainst !== password) {
      event.target.setCustomValidity('Wachtwoorden komen niet overeen');
    } else {
      event.target.setCustomValidity('');
    }
  }

  function validateRegex(
    event: Event,
    regex: RegExp,
    message: string,
    optional: boolean | null | undefined = false
  ) {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    const input = event.target;

    const isValid = regex.test(input.value);

    if (optional && input.value.length === 0) {
      input.setCustomValidity('');
    } else {
      !isValid ? input.setCustomValidity(message) : input.setCustomValidity('');
    }
  }

  function isInputValid(text: string, regex: RegExp): boolean {
    return regex.test(text);
  }

  function setValidationErrors(errors: Record<string, string>) {
    validationErrors.value = Object.values(errors);
  }

  function requiredField(event: Event) {
    if (!(event.target instanceof HTMLInputElement) || event.target.value.length > 0) {
      return;
    }

    event.target.setCustomValidity('Vul dit veld in.');
  }

  return {
    validateSamePassword,
    validateRegex,
    setValidationErrors,
    requiredField,
    isInputValid,
    validationErrors,
  };
}
