export const regex = {
  password: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@.#$%^&*_+=-]).{10,}$'),
  // 10 characters long, at least 1 uppercase, 1 lowercase, 1 number and 1 special character.
  phoneNumber: /^(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)[\d-]+$/,
  // phoneNumber: Doesn't allow letters, atleast 8 digits and accept hyphens (optional).
  telePhoneNumber: /^(?:\d{3}-\d{7}|\d{4}-\d{6})$/,
  // telePhoneNumber: Allows digits only in the following order: xxx-xxxxxxx OR xxxx-xxxxxx.
  generalPhoneNumber: /^(?:\d{2}-\d{8}|\d{3}-\d{7})$/,
  // generalPhoneNumber: Allows digits only in the following order: xx-xxxxxxxx OR xxx-xxxxxxx. (Most common way).
  email: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
  // 1111AA & 1111 AA (Netherlands) 1111 (Belgium) 11111 (Germany)
  postalCode: {
    nl: /^\d{4}\s?[a-zA-Z]{2}$/,
    be: /^([1-9][0-9]{3})$/,
    de: /^[0-9]{5}$/,
  },
  onlyNumbers: new RegExp('^(?!.*[a-zA-Z])[0-9]+'),
  kvkNumber: new RegExp(/^\d{1,8}$/),
  // kvkNumber: Only allow digits. Between 1 and 6 total digits required.
  numbersOrLetters: new RegExp('^[a-zA-Z0-9]+$'),
  payment: new RegExp(/^\d+(?:,\d{0,2})?$/),
} as const;
